<template lang="pug">
  .headindex(v-if="!$store.state.pcshow")
    swiper
    .con1
      ul(class="clearfix")
        li(v-for="(item, index) in list1" :key="index")
          img(:src="item.img")
          h2 {{ item.h2 }}
          p {{ item.p }}
          span {{ item.options }}
    .con2
      ul(class="clearfix")
        li(v-for="(item, index) in list3" :key="index")
          img(:src="item.img")
          h2 {{ item.h2 }}
      .h2 提供优质的临床试验技术与客户携手共赢
      .p 公司活力充足，在临床试验过程中操作更为灵活，专业但不过度专业。严格遵循各环节SOP， 确保流程规范与高质量数据。注入更多高素质人才，专业领域做精做强，与此同时，与药企 形成纵向一体化，承担一定的项目风险。沟通能力强、分工明确，可根据CFDA及其下属部门 的相关法规、指导原则等要求进行项目的设计及研究。
      .btn(@click="toBusinessIntro") 查看更多
    .con3
      .h2 致力于成为CRO行业领导者，为健康事业持续创造新的价值
      .p 与推动行业发展的合作伙伴一道，整合医疗行业资源，探索创新业务体系，提供优质的临床试验技术，以专业规范的一站式CRO服务体系，帮助客户高效的开展医药服务。
      .ul.clearfix
        .li
          img(src="../assets/img/banner.png")
          h4 临床研究一站式服务
          p 具有丰富的临床研究资源，我们的团队能够进行合理的方案设计、方案执行、数据解读，为您提供专业的意见与建议。
        .li
          img(src="../assets/img/banner1.png")
          h4 临床研究信息化服务
          p 为您提供人员管理、合同财务管理、进度管理以及质量管理等，直接协助临床研究的进行，在减轻研究者负担的同时，保证研究质量。
        .li
          img(src="../assets/img/banner2.png")
          h4 人类遗传办申报服务
          p 合作单位“遥领科技”可以提供精准、高效、全方位的数据管理与生物统计分析服务。
        .li
          img(src="../assets/img/banner3.png")
          h4 真实世界数据研究服务
          p 能给予全面的服务与支持，包括战略建议、医学写作、文献综述、协议制定、培训、患者教育等。
  .header(v-else)
    swiper
    .content
      ul.wow.bounceInUp(data-wow-delay="0.2s")
        li(v-for="(item, index) in list" :key="index")
          img(:src="item.img")
          h2 {{ item.h2 }}
          p {{ item.p }}
      .min1
        div
          h2 提供优质的临床试验技术与客户携手共赢
          h4
          p 公司活力充足，在临床试验过程中操作更为灵活，专业但不过度专业。严格遵循各环节SOP， 确保流程规范与高质量数据。注入更多高素质人才，专业领域做精做强，与此同时，与药企 形成纵向一体化，承担一定的项目风险。沟通能力强、分工明确，可根据CFDA及其下属部门 的相关法规、指导原则等要求进行项目的设计及研究。
          div.btn1(@click="toBusinessIntro") 查看更多
        img.wow.bounceInRight(data-wow-delay="0.2s" src="../assets/img/img.png")
    img.imgbg.wow.bounceInUp(data-wow-delay="0.2s" src="../assets/img/bg.png")
    .content
      h2.h2.wow.bounceInUp(data-wow-delay="0.2s") 致力于成为CRO行业领导者，为健康事业持续创造新的价值
      h4.h4.wow.bounceInUp(data-wow-delay="0.2s")
      p.p.wow.bounceInUp(data-wow-delay="0.2s") 与推动行业发展的合作伙伴一道，整合医疗行业资源，探索创新业务体系，提供优质的临床试验技术，以专业规范的一站式CRO服务体系，帮助客户高效的开展医药服务。
      .flix.wow.bounceInRight(data-wow-delay="0.2s")
        img(src="../assets/img/banner.png")
        .mins
          h2 A
          h3 临床研究一站式服务 
          p 具有丰富的临床研究资源，我们的团队能够进行合理的方案设计、方案执行、数据解 读，为您提供专业的意见与建议。
          div.btn1(@click="toBusinessIntro") 查看更多
      .flix.wow.bounceInRight(data-wow-delay="0.2s")
        .mins
          h2 B
          h3 临床研究信息化服务  
          p 为您提供人员管理、合同财务管理、进度管理以及质量管理等，直接协助临床研究的 进行，在减轻研究者负担的同时，保证研究质量。 
          div.btn1(@click="toBusinessIntro") 查看更多
        img(src="../assets/img/banner1.png")
      .flix.wow.bounceInRight(data-wow-delay="0.2s")
        img(src="../assets/img/banner2.png")
        .mins
          h2 C
          h3 人类遗传办申报服务  
          p 合作单位“遥领科技”可以提供精准、高效、全方位的数据管理与生物统计分析服务。 
          div.btn1(@click="toBusinessIntro") 查看更多
      .flix.wow.bounceInRight(data-wow-delay="0.2s" style="margin-bottom:233px")
        .mins
          h2 D
          h3 真实世界数据研究服务 
          p 能给予全面的服务与支持，包括战略建议、医学写作、文献综述、协议制定、培训、 患者教育等。  
          div.btn1(@click="toBusinessIntro") 查看更多
        img(src="../assets/img/banner3.png")
</template>

<script>
import swiper from "@/components/Swipe"
import {WOW} from 'wowjs';
export default{
  name: 'Index',
  components: {
    swiper
  },
  data() {
    return {
      list: [
        {
          img: require('../assets/img/img6.png'),
          h2: '行业内资质齐全的企业',
          p: 'The industry most complete enterprise qualification'
        },
        {
          img: require('../assets/img/img7.png'),
          h2: '技术研发实力与国际接轨',
          p: 'Technology R & D strength line with international standards'
        },
        {
          img: require('../assets/img/img8.png'),
          h2: '国内重要城市全覆盖',
          p: 'Full coverage of major cities in China'
        }
      ],
      list1: [
        {
          options: 'A',
          img: require('../assets/img/con1.png'),
          h2: '行业内资质齐全的企业',
          p: 'Enterprises with complete qualifications in the industry'
        },
        {
          options: 'B',
          img: require('../assets/img/con2.png'),
          h2: '技术研发实力与国际接轨',
          p: 'Technology R & D strength line with international standards'
        },
        {
          options: 'C',
          img: require('../assets/img/con3.png'),
          h2: '高效的数据管理',
          p: 'Efficient data management'
        },
        {
          options: 'D',
          img: require('../assets/img/con4.png'),
          h2: '国内重要城市全覆盖',
          p: 'Full coverage of major cities in China'
        }
      ],
      list3: [
        {
          img: require('../assets/img/con5.png'),
          h2: '专业化',
        },
        {
          img: require('../assets/img/con6.png'),
          h2: '完整性',
        },
        {
          img: require('../assets/img/con7.png'),
          h2: '高效率',
        },
        {
          img: require('../assets/img/con8.png'),
          h2: '立体化',
        }
      ],
    }
  },
  methods:{
    toBusinessIntro(){
      this.$router.push({name:'BusinessIntroduction',params:{id:1}});
      
    }
  },
  mounted(){
    this.$nextTick(() => { 
     const wow = new WOW({
        live: false
      })
      wow.init()

    })
  }
}
</script>

<style scoped lang="stylus">
.header
  padding-top:90px;
.demo
  width 200px
  height 200px
  background red
.content
  width 1280px
  margin 0 auto
  // A B C 模块
  ul  
    margin-top 120px
    display flex
    justify-content space-between
    li
      width 385px
      height 359px
      text-align center
      &:nth-of-type(1)
        background url("../assets/img/img1.png")
      &:nth-of-type(2)
        background url("../assets/img/img2.png")
      &:nth-of-type(3)
        background url("../assets/img/img3.png")
      img
        margin-top 125px
      h2
        font-size 20px
        font-family Source Han Sans CN
        font-weight bold
        color #333333
        height 20px
        margin-top 29px
      p
        line-height 23px
        font-weight 400
        color #999999
        font-size 15px
        width 228px
        margin 27px auto
  // 第二模块
  .min1
    display flex
    margin-top 132px
    justify-content: space-between;
    div
      width 606px
      h2
        font-size 30px
        height 30px
        line-height 30px
        font-size 30px  
        color #02B2B5
        margin 83px 0 41px
      h4
        width 42px
        height 5px
        background #02B2B5
      p
        line-height 30px
        font-size 14px
        font-weight 400
        color #333333
        margin 39px 0 35px
  .btn1:hover
    width 106px !important
    height 40px !important
    text-align center
    line-height 40px
    color #FFFFFF
    background-color	#02B2B5
    border none
  .btn1
    width 106px !important
    height 40px !important
    text-align center
    border 1px solid #02B2B5
    line-height 40px
    color #02B2B5
    background #fff
    cursor pointer
    img
      margin-right 74px 
      width 420px
      height 420px
// 流水背景图
.imgbg
  margin-top 78px
  width 100%
.content
  .h2
    font-size 30px
    height 30px
    line-height 30px
    font-size 30px
    color #02B2B5
    margin 70px 0 40px
    text-align center
  .h4
    width 42px
    height 5px
    background #02B2B5
    margin 0 auto
  .p
    line-height 30px
    font-size 15px
    font-weight 400
    color #333333
    text-align center
    margin 39px auto 0
    width 870px
  .flix
    display flex
    justify-content: space-between;
    margin-top 100px
    img
      width 548px
    .mins
      margin 33px 107px 0 59px
      width 567px
      h2
        font-size 120px
        font-weight bold
        color: F0F6F6
        opacity 0.1
        line-height 100px
      h3
        font-size 30px
        height 24px
        line-height 24px
        font-size 24px
        color #02B2B5
        margin-top 20px
      p
        line-height 30px
        font-size 15px
        color #333333
        margin 34px 0 40px 0
</style>
<style>
  @media screen and (max-width: 960px){
    .swiper-slide img{
      display: block;
      width: 7.5rem;
      /* height: 4rem; */
    }
    .swiper-button-prev,.swiper-button-next{
      display: none !important;
    }
    .con1{
      display: block;
      padding: 0.4rem 0.35rem 0 0.34rem;
      background: #F5F5F5;
      padding-bottom: 0.35rem;
    }
    .con1>ul{
      display: block;
      width: 100%;
    }
    .con1>ul>li{
      display: block;
      float: left;
      width: 3.22rem;
      height: 3.22rem;
      background: url('../assets/img/index1.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
    }
    .con1>ul>li:nth-child(1),.con1>ul>li:nth-child(3){
      margin-right: 0.3rem;
    }
    .con1>ul>li>img{
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      margin: 0 auto;
      padding-top: 0.97rem;
    }
    .con1>ul>li>h2{
      display: block;
      width: 100%;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      line-height: 0.39rem;
      margin: 0.18rem auto;
      text-align: center;
    }
    .con1>ul>li>p{
      width: 100%;
      font-size: 0.22rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 0.32rem;
      text-align: center;
    }
    .con1>ul>li>span{
      display: block;
      position: absolute;
      width: 100%;
      top: 0.16rem;
      font-size: 0.4rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      left: 0;
      z-index: 10000;
      text-align: center;
    }
    .con2{
      display: block;
      width: calc( 100% - 0.68rem );
      padding: 0 0.34rem;
      padding-bottom: 0.32rem;
      border-bottom: 0.32rem solid #F5F5F5;
    }
    .con2>ul{
      width: 100%;
      padding-top: 0.39rem;
    }
    .con2>ul>li{
      width: 1.14rem;
      float: left;
      margin-right: 0.75rem;
    }
    .con2>ul>li:last-child{
      margin-right: 0;
    }
    .con2>ul>li>img{
      display: block;
      width: 1.14rem;
      height: 1.14rem;
    }
    .con2>ul>li>h2{
      padding-top: 0.28rem;
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .con2 .h2{
      padding-top: 0.55rem;
      padding-bottom: 0.31rem;
      font-size: 0.3rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.39rem;
    }
    .con2 .p{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 0.45rem;
    }
    .con2 .btn{
      display: block;
      width: 2rem;
      height: 0.68rem;
      line-height: 0.68rem;
      background: #02B2B5;
      border-radius: 0.4rem;
      font-size: 0.26rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      margin-top: 0.3rem;
    }
    .con3{
      display: block;
      width: calc( 100% - 0.68rem );
      padding: 0 0.34rem;
      /* padding-bottom: 0.32rem; */
      border-bottom: 0.32rem solid #F5F5F5;
    }
    .con3 .h2{
      padding-top: 0.55rem;
      padding-bottom: 0.10rem;
      font-size: 0.26rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 0.39rem;
    }
    .con3 .p{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 0.45rem;
      text-align: center;
    }
    .con3 .ul{
      padding-top: 0.28rem
    }
    .con3 .ul .li{
      display: block;
      float: left;
      width: 3.22rem;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 0.1rem 0.1rem;
      height:4.93rem;
      margin-bottom:0.33rem;
      position: relative;
    }
    .con3 .ul .li:nth-child(2n-1){
      margin-right:0.38rem
    }
    .con3 .ul .li>img{
      display: block;
      width: 3.22rem;
      height: 2.02rem;
    }
    .con3 .ul .li>h4{
      font-size: 0.3rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      padding:0.19rem 0 0.1rem 0.23rem
    }
    .con3 .ul .li>p{
      font-size: 0.24rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 0.34rem;
      padding:0rem 0.24rem 0rem 0.24rem
    }
  }
  @media screen and (min-width: 960px){
    .swiper-button-prev {
      width:71px;
      height:72px;
      background-image: url('../assets/img/img9.png');
      left: 10px;
      right: auto;
    }
    .swiper-button-prev:hover {
      width:71px;
      height:72px;
      background-image: url('../assets/img/img10.png');
      left: 10px;
      right: auto;
    }
    .swiper-button-next {
      width:71px;
      height:72px;
      background-image: url('../assets/img/img11.png');
      right: 10px;
      left: auto;
    }
    .swiper-button-next:hover {
      width:71px;
      height:72px;
      background-image: url('../assets/img/img12.png');
      right: 10px;
      left: auto;
    }
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      top: 50%;
      width:71px;
      height:72px;;
      margin-top: -22px;
      z-index: 10;
      cursor: pointer;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
</style>