<template lang="pug">
  .recommendPage
    .wrapper
      swiper(:options="swiperOption" ref="mySwiper")
        swiper-slide(v-for="(item, i) in picList" :key="i")
          img(:src="item.src")
        //- .swiper-pagination(slot="pagination") 
        //- 左右箭头
        .swiper-button-prev(slot="button-prev")
        .swiper-button-next(slot="button-next")
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'HomeSwiper',
  components: {
    swiper,
    swiperSlide
  },
   data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      picList:[
          {
            id: 0,
            src: require('../assets/img/img4.jpg')
          },
          {
            id: 0,
            src: require('../assets/img/img13.jpg')
          }
      ]
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    console.log("this is current swiper instance object", this.swiper);
  },
   methods: {
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.swiper-slide
  img
    width 100%
</style>
